import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import { noitem } from "../../../assets/images";
import "./failurePage.css";
import { withTranslation } from "react-i18next";

const failurePage = (props: any) => {
  const { t } = props;
  //render header
  const renderHeaderBar = () => {
    return (
      <div>
        <HeaderScreen />
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="main_login flt">
        <FooterScreen />
      </div>
    );
  };
  return (
    <>
      {renderHeaderBar()}
      <div className="thankyou_container flt">
        <div className="row">
          <div className="col-sm-12">
            <div className="thankyou_box flt">
              <div className="thankyou_image flt">
                <img src={noitem} alt="noimage" />
              </div>
              {t("failure")}
            </div>
          </div>
        </div>
      </div>
      {renderFooter()}
    </>
  );
};
export default withTranslation()(failurePage);
