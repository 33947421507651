import "./App.css";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Provider } from "react-redux";
import store from "./store";
import { useEffect } from "react";
import { alertActions } from "./actions/alert.actions";
import "./assets/css/style.css";
import Home from "./components/pages/Home/Home";
import Login from "./components/pages/login/Login";
import RegistrationScreen from "./components/pages/registration/RegistrationScreen";
import ForgotPassword from "./components/pages/forgotPassword/ForgotPassword";
import GetOtp from "./components/pages/getOtp/GetOtp";
import { userLogout } from "./actions/UserAction";
import { header } from "./actions/HeaderAction";
import ProductList from "./components/pages/productList/ProductList";
import ProductDetails from "./components/pages/productDetails/ProductDetails";
import Cart from "./components/pages/cartPage/Cart";
import Checkout from "./components/pages/checkout/Checkout";
import AboutUs from "./components/pages/aboutus/AboutUs";
import PlanOfPurchase from "./components/pages/planofpurchase/PlanOfPurchase";
import Contact from "./components/pages/contact/Contact";
import ThankYou from "./components/pages/thankYou/ThankYou";
import MyAccount from "./components/pages/myaccount/MyAccount";
import MyOrder from "./components/pages/myorders/MyOrder";
import PrivacyPolicy from "./components/pages/privacypolicy/PrivacyPolicy";
import TermsCondition from "./components/pages/termsCondition/TermsCondition";
import Refund from "./components/pages/refund/Refund";
import Wishlist from "./components/pages/wishlist/WishList";
import FailurePage from "./components/pages/failurePage/failurePage";

//App
const Main = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useSelector((state) => state?.alert);
  const user = JSON.parse(localStorage.getItem("state"));
  const isUser = user ? Object.keys(user.userData).length : 0;
  const session_expired = user?.userData?.session_expired;

  useEffect(() => {
    setTimeout(() => dispatch(alertActions.clear()), 3000);
    // eslint-disable-next-line
  }, [alert.message]);

  useEffect(() => {
    dispatch(header())
      .then((response) => {
        console.log("headerResponse", response);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
    // eslint-disable-next-line
  }, []);

  //session expire
  useEffect(() => {
    if (session_expired === true) {
      dispatch(alertActions.error("Authentication error..Please login"));
      setTimeout(() => {
        dispatch(userLogout());
        navigate("/login");
      }, 10000);
    }
    // eslint-disable-next-line
  }, [session_expired]);

  return (
    <div className="flt">
      {alert.message && (
        <div className={`alert ${alert.type}`}>{alert.message}</div>
      )}

      <Routes>
        <Route
          path="/Login"
          element={isUser ? <Navigate to="/" /> : <Login />}
        />
        <Route path="/" element={<Home />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsCondition />} />
        <Route path="/cancellation-refund" element={<Refund />} />
        <Route path="/myorders" element={<MyOrder />} />
        <Route path="/myaccount" element={<MyAccount />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/failure" element={<FailurePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/planofpurchase" element={<PlanOfPurchase />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/checkout/:title" element={<Checkout />} />
        <Route path="/product-details/:title" element={<ProductDetails />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route
          path="/registration"
          element={isUser ? <Navigate to="/" /> : <RegistrationScreen />}
        />
        <Route path="/getOtp" element={<GetOtp />} />
        <Route path="/product-list/:title" element={<ProductList />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

function App() {
  return (
    <Provider store={store}>
      <Main />
      {/* <AppWithNavigationState /> */}
    </Provider>
  );
}

export default App;
